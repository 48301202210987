import React, { Fragment } from "react";
import { ThemeProvider } from "styled-components";
import Layout from "../containers/Home/Layout";
import { interiorTheme } from "common/theme/interior";
import Titlebar from "common/components/Titlebar";
import "common/assets/css/main-page.css";
import FeedbackCSS from "../containers/Home/feedback.style";
import { useState } from "react";
import { Rating } from "react-simple-star-rating";

import Seo from "components/seo";

const Feedback = () => {
   
  const seoData = {
    title: 'Share Your Feedback - Radiance | Let Us Hear From You',
    description: 'Share your valuable feedback with Radiance. Help us improve our services to provide you with the best hair care and wig solutions tailored to your needs.',
    keywords: ['Feedback Page']
  };

  const schemaData = {
    "@context": "https://schema.org",
    "@type": "Service",
    "@id": "Feedback",
    "additionalType": "https://www.radiancehairstudio.com/feedback.php",
    "alternateName": "Radiance Hair Studio",
    "areaServed": "New Delhi",
    "category": "https://www.radiancehairstudio.com/feedback.php",
    "description": "Share your valuable feedback with Radiance. Help us improve our services to provide you with the best hair care and wig solutions tailored to your needs.",
    "disambiguatingDescription": "Feedback Page",
    "image": "https://www.radiancehairstudio.com/home/feedback-banner.jpg",
    "logo": "https://www.radiancehairstudio.com/static/radiance-logo-721b0cd4def3d400c04ba899afd38810.png",
    "mainEntityOfPage": "Feedback Page"
  };  

  const [rating, setRating] = useState(0);

  // Catch Rating value
  const handleRating = (number) => {
    setRating(number);

    // other logic
  };
  // Optinal callback functions

  return (
    <ThemeProvider theme={interiorTheme}>
      <Fragment>
      <Seo {...seoData} schemaData={schemaData} />
        <Layout>
          <Titlebar title="Feedback" />
          <FeedbackCSS>
            <div id="feedbackPage">
              <div className="myContainer">
                <form>
                  <div className="row">
                    <img src="/home/feedback-banner.jpg" alt="Feedback Radiance" />
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Your name"
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Your email"
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Your location"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <textarea
                          className="form-control"
                          placeholder="Your feedback to improve my services"
                          style={{ height: "147px" }}
                        ></textarea>
                      </div>
                    </div>
                  </div>

                  <div className="form-group fo">
                    <Rating
                      onClick={handleRating}
                      /* Available Props */
                    />
                  </div>
                  <div className="text-center">
                    <button type="submit" className="btn btn-warning">
                      Send Now
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </FeedbackCSS>
        </Layout>
      </Fragment>
    </ThemeProvider>
  );
};
export default Feedback;
